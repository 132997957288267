@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply max-w-[100vw] overflow-x-hidden font-site bg-site-accent-500;
  }

  a {
    @apply no-underline;
  }

  a:hover {
    @apply no-underline !text-site-base;
  }

  .smooth {
    @apply transition-all duration-200;
  }

  .lossekruumel .active {
    @apply font-bold;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  textarea {
    @apply resize-none;
  }
}

@layer components {
  .btn {
    @apply btn--base btn--pseudo bg-site-conversion border-site-conversion border-2 hover:border-site-conversion-200 hover:bg-site-conversion-200 text-white active:bg-site-conversion-50 [&:not([disabled])]:focus:!bg-site-conversion-200;
  }

  .btn--alt {
    @apply btn--base btn--pseudo bg-site-secondary border-site-secondary border-2 hover:border-site-conversion-200 hover:bg-site-conversion-200 text-white [&:not([disabled])]:focus:!bg-site-conversion-200;
  }

  .btn--outline {
    @apply btn--base btn--pseudo bg-transparent border-site-conversion border-2 hover:border-site-conversion-200 hover:bg-site-conversion-200 text-site-conversion hover:text-white [&:not([disabled])]:focus:!bg-site-conversion-200;
  }

  .section {
    @apply py-6 md:py-8 lg:py-16;
  }

  .content {
    @apply content--base content--pseudo content--paragraphs content--headings;
  }

  .content.white {
    @apply text-white children-p:text-white;
  }
}

@layer utilities {
  .btn--pseudo {
    @apply transition-colors disabled:opacity-75;
  }

  .btn--base {
    @apply py-2 px-6 text-center rounded-full text-sm inline-flex font-bold;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }

  .content--pseudo {
    @apply children-a:underline hover:children-a:text-site-accent;
  }

  .content--base {
    @apply last:[&>*]:mb-0 children-b:font-bold children-strong:font-bold;
  }

  .content--table {
    @apply children-td:!text-white children-strong:children-td:!text-white;
  }

  .content--paragraphs {
    @apply children-p:leading-7 max-sm:children-p:!leading-[1.8] max-sm:children-p:text-sm children-p:mb-4 children-p:font-light children-p:text-site-nav;
    @apply children-li:font-light children-li:leading-7 children-li:!list-disc children-li:text-site-nav;
    @apply children-ul:pl-8;
    @apply children-strong:children-table:text-site-base children-strong:children-table:text-base sm:children-strong:children-table:text-xl;
    @apply children-td:text-base children-td:font-light children-td:text-site-nav children-td:align-top max-lg:children-td:py-1 max-lg:children-td:!w-full;
    @apply max-lg:children-tr:flex max-lg:children-tr:flex-wrap;
  }

  .title {
    @apply text-4xl lg:text-6xl !leading-[1.2] font-bold text-site-base;
  }

  .title-sm {
    @apply text-2xl lg:text-5xl !leading-[1.2] font-bold text-site-base;
  }

  .content--headings {
    @apply children-headings:mb-4 children-h1:mb-5 children-h1:text-3xl children-h2:text-2xl children-h3:text-xl children-headings:font-bold children-headings:text-site-nav;
  }
}

/* WordPress helpers */

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply my-0 mx-auto block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption-text {
  @apply text-center text-base font-bold;
}

/* Progress bar */

#nprogress .bar {
  @apply bg-site-accent;
}

#nprogress .spinner-icon {
  @apply hidden;
}

#nprogress .peg {
  @apply shadow;
}

/* Form helpers */

.form-duuf-submit > button {
  @apply btn mt-5;
}

.form-duuf-group {
  @apply relative mb-4;
}

.form-duuf-loading {
  @apply absolute inset-0 z-10 blur flex-center;
}

.form-duuf-label {
  @apply block text-site-nav mb-1;
}

.form-duuf-error {
  @apply absolute top-0 right-0 text-xs;
}

.form-duuf-input,
.form-duuf-select__control {
  @apply rounded-full;
}

.form-duuf-textarea {
  @apply rounded-xl min-h-[132px];
}

.form-duuf-input,
.form-duuf-textarea,
.form-duuf-select__control {
  @apply text-site-base bg-white px-4 py-2 sm:min-w-[267px] w-full;
}

.form-duuf-group-name {
  @apply flex-col gap-0;
}

.form-duuf-group-consent {
  @apply [&>div]:!items-start [&_input]:mt-[3px] [&_input]:mr-[8px] text-sm;
}

.form-duuf-group-consent a {
  @apply underline hover:opacity-75 transition-opacity;
}

.form-duuf-error-consent {
  @apply right-[unset] top-[unset] left-[20px] -bottom-[60%];
}

.form-duuf-file input {
  @apply hidden;
}

.form-duuf-file-button button {
  @apply btn--alt py-1 px-2;
}

/* form-contact */

.form-contact .form-duuf-label {
  @apply font-bold;
}

.form-contact .form-duuf-group {
  @apply mb-6;
}

.form-contact .form-duuf-submit > button {
  @apply mt-2;
}

/* End form-contact */

/* form-apply */

.form-apply > form {
  @apply grid grid-cols-6 gap-x-7 gap-y-4;
}

.form-apply .form-duuf-group,
.form-apply .form-duuf-group-name {
  @apply col-span-6 sm:col-span-3;
}

.form-apply .form-duuf-group-textarea {
  @apply col-span-6;
}

.form-apply .form-duuf-label {
  @apply font-bold;
}

.form-apply .form-duuf-submit > button {
  @apply mt-1;
}

.form-apply .form-duuf-group-consent {
  @apply mr-4;
}

/* End form-apply */

.form-duuf-group-submit {
  @apply !mb-0;
}

/* Custom */
